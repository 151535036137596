<template>
  <b-container>
    <!---<b-jumbotron
      header-tag="h2"
      lead-tag="h4"
    >
      <template #header>
        {{ $t('aboutus.title')[0] }}
      </template>

      <template #lead>
        {{ $t('aboutus.title')[1] }}
      </template>
    </b-jumbotron>--->

    <video
      width="100%"
      loop
      autoplay
      :src="`https://res.cloudinary.com/booknites/video/upload/vc_auto/assets/AboutUs_${$i18n.locale.toUpperCase()}.mp4`"
    />

    <!-- <page subject="page.about" /> -->

    <StoryBlokContent slug="cdn/stories/about" />

    <b-row
      v-for="(id) in videoUrls"
      :key="id"
      class="my-4 py-4"
    >
      <b-col>
        <b-card-title class="text-dark">
          {{ $t(`aboutus.title_${id}`) }}
        </b-card-title>

        <b-list-group>
          <b-list-group-item
            v-for="(label,index) in $t(`aboutus.text_${id}`)"
            :key="index"
          >
            {{ label }}
          </b-list-group-item>
        </b-list-group>
      </b-col>

      <b-col>
        <video
          width="100%"
          controls
          :src="`https://res.cloudinary.com/booknites/video/upload/video/${id}-${$i18n.locale}.mp4`"
        />
        <!---<cld-video
          fluid="true"
          cloud-name="booknites"
          :key="id"
          :public-id="`/video/${id}-${$i18n.locale}`"
          controls="true"
          width="640"
          height="352"
        />--->
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import StoryBlokContent from '../components/StoryBlokContent.vue'
// import Page from './Page.vue'

export default {
  components: { StoryBlokContent },
  computed: {
    videoUrls: function () {
      return [
        'booking',
        'aanbieder'
        // 'investor'
      ]
    }
  }
}
</script>
